<template>
    <div class="tags-info">
        <h3 class="tags__title">
            Tags
        </h3>
        <div class="tags__filters">
            <p class="tags__select">
                <v-selectize v-model="selected" :options="['Show active','Show archived', 'Show all']"/>
            </p>
            <input class="tags__input tags__search" type="text" v-model="search" placeholder="Searh by name">
            <input class ="tags__input tags__new" type="text" v-model="form['title']" placeholder="New tag">
            <button class="tags__add" @click="insertMethod">
                <span>Add</span>
            </button>
        </div>
        <EditTagModal  v-if="showEditTagModal"  @close="showEditTagModal = false" />
        <div class="bg" v-if="showEditTagModal" @close="showEditTagModal = false"></div>
    </div>
</template>

<script>
import EditTagModal from '@/components/Modal/EditTagModal' 
import 'selectize/dist/css/selectize.default.css' // This is required, we use the same css as selectize.js
import VSelectize from '@isneezy/vue-selectize'
import { useTagStore } from '@/store/TagStore'

export default {
    data: () => {
        const tagStore = useTagStore();
        return {
            selected: 'Show active',
            search: '',
            showEditTagModal: false,
            store: tagStore,
            form: []
        }
    },
    watch: {
        selected() {
            this.changeFilter()
        },
        search(oldSearch, newSearch) {
            if ( oldSearch != newSearch)
                this.changeSearch()
        }
    },
    components: {
        VSelectize, EditTagModal
    },
    methods: {
        changeSearch(){
            this.store.changeSearch(this.search)
        },
        changeFilter() {
            let filter = 'active';
            switch(this.selected){
                case 'Show active':
                    filter = 'active';
                    break;
                case 'Show archived':
                    filter = 'archived';
                    break;
                case 'Show all':
                    filter = 'all';
                    break;
            }
            this.store.changeFilter(filter)
        },
        insertMethod() {
            this.store.insert(this.form).then(response => {
                if ( response.data.result=='ok'){
                    this.form['title'] = ''
                }else
                    this.errors.push(response.data.message);
            })
            .catch(e => {
                this.errors.push(e)
            })
        }
    }
}
</script>


<style scoped>
    button{
        border: none;
        background: none;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        background: #9CEDFF1A;
        border: 2px solid #9CEDFF;
        height: 42px;
        border-radius:30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .tags-info{
        padding: 20px 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding-bottom: 0;
    }

    .tags__title{
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
        margin: 0;
        padding: 15px 30px 10px 0;
        border-radius: 30px 30px 0 0;
        display: inline-block;
        z-index: 5;
    }

    .tags__add{
        width: 84px;
        margin-left: 10px;
        margin-right: 0;
    }

    .tags__input{
        height: 42px;
        border: 2px solid #EAEEF1;
        font-family: 'Montserrat', sans-serif;
        border-radius: 39px;
        padding: 0 20px;
        width: 300px;
        box-sizing: border-box;
        outline: none;
        max-width: 729px;
        align-self: center;
        justify-self: unset;
        background-repeat: no-repeat;
        background-position: 98%;
        margin-left: 5px;
        cursor: pointer;
    }

    .tags__search{
        background-image: url(../../assets/img/lupa.svg);
        background-repeat: no-repeat;
        background-position: 95%;
        padding-right: 30px;
    }

    .tags__select{
        margin: 0;
    }

    .tags__new{
        margin-left: auto;
    }

    .tags__filters{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        flex-wrap: wrap;
    }

    @media(max-width:910px){
        .tags__filters{
            justify-content: flex-start;
        }

        .tags__new{
            margin-left: 0;
        }
    }

    @media(max-width:610px){
        .tags__search{
            margin-left: 0;
        }

        .tags__new{
            margin-top: 5px;
        }

        .tags__add{
            margin-top: 5px;
            margin-left: auto;
        }
    }

    @media(max-width:560px){
        .tags-info{
            padding: 10px 15px;
        }
    }
    
    
    .tags__select{
            position: relative;
            width: 175px;
            margin-right: 10px;
            margin-top: 5px;
    }
</style>