<template>
  <div>
    <TagsInfo />
    <TagsWrp />
  </div>
</template>

<script>
    import TagsInfo from '@/components/Tags/TagsInfo'
    import TagsWrp from '@/components/Tags/TagsWrp'


    export default {
        components: {
          TagsInfo, TagsWrp
        }
    }
</script>

<style scoped>

</style>